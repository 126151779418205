#root {
    /* max-width: 1380px; */
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    a:nth-of-type(2) .logo {
        animation: logo-spin infinite 20s linear;
    }
}
